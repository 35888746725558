
export default {
    data() {
        return {
            timer: '--:--',
            interval: null,
            isExisting: null,
            loading: false,
            isDepositSuccessful: false,
            isDepositFailed: false,
        }
    },
    computed: {
        checkExist() {
            return this.$store.state.themes.deposit.checkExist
        },
    },
    watch: {
        checkExist(value) {
            this.isExisting = value.is_exist
            if (value.is_exist) {
                this.startTimer()
            }
        },
    },
    beforeDestroy() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        // unsubscribe the store action
        if (this.subscribeAction) {
            this.subscribeAction()
        }
    },
    mounted() {
        this.initSocketTheme()
        // unsubscribe vuex mutation
        this.subscribeAction = this.$store.subscribeAction(
            (mutation, state) => {
                if (
                    mutation.type === 'sockets/revokeDepositByAdmin' ||
                    mutation.type === 'sockets/confirmDepositByAdmin' ||
                    mutation.type === 'sockets/successfulDeposit'
                ) {
                    if (
                        mutation.type === 'sockets/successfulDeposit' ||
                        mutation.type === 'sockets/confirmDepositByAdmin'
                    ) {
                        this.isDepositSuccessful = true
                    }
                    if (mutation.type === 'sockets/revokeDepositByAdmin') {
                        this.isDepositFailed = true
                    }
                    this.isExit()
                }
            }
        )
        this.isExit()
    },
    methods: {
        startTimer() {
            if (this.interval) {
                clearInterval(this.interval)
            }
            this.displayTimer()

            this.interval = setInterval(() => {
                this.displayTimer()
            }, 1000)
        },
        async isExit() {
            this.loading = true
            await this.$store.dispatch('themes/deposit/checkExist')
            this.loading = false
        },
        displayTimer() {
            const start = this.$dayjs(
                decodeURIComponent(
                    this.checkExist?.created_at || this.$route.query.created_at
                ) // Ensure this is correct
            )
            const end = this.$dayjs()
            const duration = this.$dayjs.duration(end.diff(start))
            const minutes = duration.minutes()
            const seconds = duration.seconds()

            // Create the current elapsed duration correctly
            const currentDuration = this.$dayjs.duration({
                minutes,
                seconds,
            })

            // Define the total duration correctly and subtract 10 seconds
            const totalDuration = this.$dayjs
                .duration(10, 'minutes')
                .subtract(10, 'seconds')

            // Calculate time left
            const timeLeft = totalDuration.subtract(currentDuration)

            const minutesLeft = timeLeft.minutes()
            const secondsLeft = timeLeft.seconds()

            // Instead of window.close(), show a message when time runs out
            if (minutesLeft <= 0 && secondsLeft <= 1) {
                if (this.interval) {
                    clearInterval(this.interval)
                }
                this.isExisting = false
                this.isDepositFailed = true
                this.timer = '00:00' // Set timer to 00:00
            }

            // Format and update the timer display
            const minutesFormatted = minutesLeft.toString().padStart(2, '0')
            const secondsFormatted = secondsLeft.toString().padStart(2, '0')

            this.timer = `${minutesFormatted}:${secondsFormatted}`
        },
    },
}
