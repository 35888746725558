
export default {
    name: 'NoBonusCard',
    props: {
        availableCommission: {
            type: String,
            default: '',
        },
        currency: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        claimRolling: {
            type: Function,
            default: () => {},
        },
    },
}
