
export default {
    name: 'PromotionHistoryCard',
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        backgroundColor: {
            type: String,
            default: '',
        },
    },
    methods: {
        getBonusType(item) {
            if (~~item.bonus_type === 1) return this.tl('promotion')
            if (~~item.bonus_type === 2) return this.tl('promoCode')
            if (~~item.bonus_type === 3) return this.tl('rollingReward')
            if (~~item.bonus_type === 4) return this.tl('inviteAndEarn')
            if (~~item.bonus_type === 5) return '' // this.tl('depositWithoutPromotion')
            return '-'
        },
    },
}
