/* eslint-disable */
/**
 * This file is copied from the source code of v-phone-input.
 * Original File Location: node_modules/v-phone-input/dist/v-phone-input.js
 *
 * The method onLazyCountryOrValueChange() has been modified to meet our specific requirements.
 */
import c from 'vue'
import { allCountries as S } from 'country-telephone-data'
import k from 'v-phone-input/node_modules/awesome-phonenumber'
import {
    VSelect as L,
    VTextField as V,
    VListItemIcon as $,
    VListItemTitle as x,
    VListItemContent as B,
} from 'vuetify/lib'
const v = c.extend({
    name: 'VPhoneCountrySpan',
    functional: !0,
    render(e, t) {
        return e(
            'span',
            {
                staticClass: `v-phone-input__country__country-icon ${t.data.staticClass}`,
            },
            t.props.decorative
                ? []
                : [
                      e('span', { staticClass: 'd-sr-only' }, [
                          t.props.country.name,
                      ]),
                  ]
        )
    },
})
const M = c.extend({
    name: 'VPhoneCountrySprite',
    functional: !0,
    render(e, t) {
        return e('span', { staticClass: 'f32' }, [
            e(v, {
                staticClass: `flag ${t.props.country.iso2.toLowerCase()}`,
                props: t.props,
            }),
        ])
    },
})
const O = c.extend({
    name: 'VPhoneCountrySvg',
    functional: !0,
    render(e, t) {
        return e(v, {
            staticClass: `fi fi-${t.props.country.iso2.toLowerCase()}`,
            props: t.props,
        })
    },
})
function F(e) {
    return 'setPreference' in e
}
function l(e) {
    return (e || '').toUpperCase()
}
function C(e, t) {
    const o = {}
    return (
        e.forEach((n) => {
            o[n[t]] = n
        }),
        o
    )
}
const w = S.map((e) => ({
    ...e,
    iso2: l(e.iso2),
}))
const b = class {
    async guess() {
        let e, t
        try {
            ;(e = await fetch(b.IP2C_URL)), (t = await e.text())
        } catch {
            return
        }
        const o = t.toString().split(';')
        if (!(!o || o[0] !== '1')) return o[1]
    }
}
const p = b
p.IP2C_URL = 'https://ip2c.org/s'
class A extends p {
    constructor() {
        super(...arguments), (this.memoCountry = void 0)
    }

    async guess() {
        return (
            this.memoCountry || (this.memoCountry = await super.guess()),
            this.memoCountry
        )
    }

    setPreference(t) {
        this.memoCountry = t
    }
}
const G = {
    label: 'Phone',
    ariaLabel: void 0,
    countryLabel: 'Country',
    countryAriaLabel: (e) => `Country for ${e.label}`,
    placeholder: void 0,
    hint: void 0,
    invalidMessage: (e) =>
        `The "${e.label}" field is not a valid phone number (example: ${e.example}).`,
    persistentPlaceholder: void 0,
    persistentHint: void 0,
    countryIconMode: void 0,
    allCountries: w,
    preferredCountries: [],
    onlyCountries: [],
    ignoredCountries: [],
    defaultCountry: void 0,
    countryGuesser: new A(),
    disableGuessingCountry: !1,
    disableGuessLoading: !1,
    enableSearchingCountry: !1,
    displayFormat: 'national',
}
const _ = { ...G }
function N(e) {
    Object.assign(_, e)
}
function r(e) {
    return _[e]
}
class d {
    static make(t, o) {
        return this.AwesomePhoneNumber((t || '').trim(), o)
    }

    static makeExample(t) {
        return this.AwesomePhoneNumber.getExample(t)
    }

    static format(t, o) {
        return t.number[o] || t.number.input
    }
}
d.AwesomePhoneNumber = k
const R = c.extend({
    name: 'VPhoneInput',
    components: {
        VSelect: L,
        VTextField: V,
        VListItemIcon: $,
        VListItemTitle: x,
        VListItemContent: B,
    },
    inheritAttrs: !1,
    props: {
        label: {
            type: [String, Function],
            default: () => r('label'),
        },
        ariaLabel: {
            type: [String, Function],
            default: () => r('ariaLabel'),
        },
        countryLabel: {
            type: [String, Function],
            default: () => r('countryLabel'),
        },
        countryAriaLabel: {
            type: [String, Function],
            default: () => r('countryAriaLabel'),
        },
        placeholder: {
            type: [String, Function],
            default: () => r('placeholder'),
        },
        hint: {
            type: [String, Function],
            default: () => r('hint'),
        },
        invalidMessage: {
            type: [String, Function],
            default: () => r('invalidMessage'),
        },
        persistentPlaceholder: {
            type: Boolean,
            default: () => r('persistentPlaceholder'),
        },
        persistentHint: {
            type: Boolean,
            default: () => r('persistentHint'),
        },
        appendIcon: {
            type: String,
            default: void 0,
        },
        appendOuterIcon: {
            type: String,
            default: void 0,
        },
        prependInnerIcon: {
            type: String,
            default: void 0,
        },
        prependIcon: {
            type: String,
            default: void 0,
        },
        clearIcon: {
            type: String,
            default: void 0,
        },
        outlined: {
            type: Boolean,
            default: void 0,
        },
        filled: {
            type: Boolean,
            default: void 0,
        },
        shaped: {
            type: Boolean,
            default: void 0,
        },
        flat: {
            type: Boolean,
            default: void 0,
        },
        solo: {
            type: Boolean,
            default: void 0,
        },
        soloInverted: {
            type: Boolean,
            default: void 0,
        },
        backgroundColor: {
            type: String,
            default: void 0,
        },
        color: {
            type: String,
            default: void 0,
        },
        light: {
            type: Boolean,
            default: void 0,
        },
        dark: {
            type: Boolean,
            default: void 0,
        },
        rounded: {
            type: Boolean,
            default: void 0,
        },
        dense: {
            type: Boolean,
            default: void 0,
        },
        height: {
            type: [String, Number],
            default: void 0,
        },
        loaderHeight: {
            type: [String, Number],
            default: void 0,
        },
        reverse: {
            type: Boolean,
            default: !1,
        },
        singleLine: {
            type: Boolean,
            default: void 0,
        },
        clearable: {
            type: Boolean,
            default: void 0,
        },
        autofocus: {
            type: Boolean,
            default: void 0,
        },
        error: {
            type: Boolean,
            default: void 0,
        },
        errorCount: {
            type: [Number, String],
            default: void 0,
        },
        errorMessages: {
            type: [Array, String],
            default: void 0,
        },
        messages: {
            type: [Array, String],
            default: void 0,
        },
        success: {
            type: Boolean,
            default: void 0,
        },
        successMessages: {
            type: [Array, String],
            default: void 0,
        },
        validateOnBlur: {
            type: Boolean,
            default: void 0,
        },
        readonly: {
            type: Boolean,
            default: void 0,
        },
        disabled: {
            type: Boolean,
            default: void 0,
        },
        loading: {
            type: Boolean,
            default: !1,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        countryIconMode: {
            type: [String, Object],
            default: () => r('countryIconMode'),
        },
        allCountries: {
            type: Array,
            default: () => r('allCountries'),
        },
        preferredCountries: {
            type: Array,
            default: () => r('preferredCountries'),
        },
        onlyCountries: {
            type: Array,
            default: () => r('onlyCountries'),
        },
        ignoredCountries: {
            type: Array,
            default: () => r('ignoredCountries'),
        },
        defaultCountry: {
            type: String,
            default: () => r('defaultCountry'),
        },
        countryGuesser: {
            type: Object,
            default: () => r('countryGuesser'),
        },
        disableGuessingCountry: {
            type: Boolean,
            default: () => r('disableGuessingCountry'),
        },
        disableGuessLoading: {
            type: Boolean,
            default: () => r('disableGuessLoading'),
        },
        enableSearchingCountry: {
            type: Boolean,
            default: () => r('enableSearchingCountry'),
        },
        displayFormat: {
            type: String,
            default: () => r('displayFormat'),
        },
        country: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        countryProps: {
            type: Object,
            default: () => ({}),
        },
        phoneProps: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            guessingCountry: !1,
            mergedRules: [],
            lazyCountry: this.country,
            lazyValue: this.value || '',
            lazyPhone: { number: { input: '' } },
        }
    },
    computed: {
        countryIconComponent() {
            switch (this.countryIconMode) {
                case 'svg':
                    return O
                case 'sprite':
                    return M
                default:
                    return this.countryIconMode
            }
        },
        fallbackCountry() {
            return (
                this.findCountry(this.defaultCountry) ||
                this.findCountry(this.preferredCountries[0]) ||
                this.filteredCountries[0]
            )
        },
        activeCountry() {
            return this.findCountry(this.lazyCountry) || this.fallbackCountry
        },
        allCountriesByIso2() {
            return C(this.allCountries, 'iso2')
        },
        filteredCountriesByIso2() {
            return C(this.filteredCountries, 'iso2')
        },
        filteredCountries() {
            if (this.onlyCountries.length)
                return this.getCountries(this.onlyCountries)
            if (this.ignoredCountries.length) {
                const e = this.ignoredCountries.map(l)
                return this.allCountries.filter(({ iso2: t }) => !e.includes(t))
            }
            return this.allCountries
        },
        countriesItems() {
            const e = this.preferredCountries.map(l)
            const t = e.length
                ? this.filteredCountries.filter(({ iso2: n }) => !e.includes(n))
                : this.filteredCountries
            const o = this.getCountries(this.preferredCountries).map((n) => ({
                ...n,
                preferred: !0,
            }))
            return (
                t.length && (o.length && o.push({ divider: !0 }), o.push(...t)),
                o
            )
        },
        phoneExample() {
            return this.formatPhoneNumber(
                d.makeExample(this.activeCountry.iso2).toJSON()
            )
        },
        computedLabel() {
            return this.computeMessage(this.label, {
                example: this.phoneExample,
            })
        },
        computedAriaLabel() {
            return this.computeMessage(this.ariaLabel, {
                example: this.phoneExample,
            })
        },
        messageOptions() {
            return {
                label: this.computedLabel || this.computedAriaLabel,
                example: this.phoneExample,
            }
        },
        computedCountryLabel() {
            return this.computeMessage(this.countryLabel, this.messageOptions)
        },
        computedCountryAriaLabel() {
            return this.computeMessage(
                this.countryAriaLabel,
                this.messageOptions
            )
        },
        computedPlaceholder() {
            return this.computeMessage(this.placeholder, this.messageOptions)
        },
        computedHint() {
            return this.computeMessage(this.hint, this.messageOptions)
        },
        computedInvalidMessage() {
            return this.computeMessage(this.invalidMessage, this.messageOptions)
        },
        computedCountryProps() {
            return {
                ...this.countryProps,
                menuProps: {
                    contentClass: 'v-phone-input__country__menu',
                    ...((this.countryProps
                        ? this.countryProps.menuProps
                        : void 0) || {}),
                },
            }
        },
        computedPhoneProps() {
            return { ...this.$attrs, ...this.phoneProps }
        },
    },
    watch: {
        computedInvalidMessage: 'onComputedInvalidMessageChange',
        rules: {
            handler: 'onRulesChange',
            immediate: !0,
            deep: !0,
        },
        displayFormat: 'onDisplayFormatChange',
        value: 'onValueChange',
        country: 'onCountryChange',
        lazyCountry: 'onLazyCountryChange',
        lazyValue: 'onLazyValueChange',
        lazyPhone: {
            handler: 'onLazyPhoneChange',
            deep: !0,
        },
    },
    mounted() {
        this.onLazyValueChange(),
            this.$nextTick(() => {
                this.initializeCountry()
            })
    },
    methods: {
        onComputedInvalidMessageChange() {
            this.onRulesChange(), this.validate()
        },
        onRulesChange() {
            const e = this.rules.map((t) =>
                typeof t === 'function'
                    ? () =>
                          t(
                              this.lazyValue || '',
                              this.lazyPhone,
                              this.messageOptions
                          )
                    : t
            )
            this.computedInvalidMessage
                ? (this.mergedRules = [
                      ...e,
                      () =>
                          !this.lazyValue ||
                          this.lazyPhone.valid ||
                          this.computedInvalidMessage,
                  ])
                : (this.mergedRules = e)
        },
        onDisplayFormatChange() {
            this.lazyPhone.valid &&
                (this.lazyValue = this.formatPhoneNumber(this.lazyPhone))
        },
        onCountryChange() {
            this.country &&
                this.country !== this.lazyCountry &&
                (this.lazyCountry = this.country)
        },
        onValueChange() {
            this.value !== this.lazyPhone.number.input &&
                this.value !== this.lazyPhone.number.e164 &&
                (this.lazyValue = this.value || '')
        },
        onLazyCountryChange(e, t) {
            this.lazyCountry
                ? ((this.guessingCountry = !1),
                  this.$emit('update:country', this.lazyCountry),
                  F(this.countryGuesser) &&
                      this.countryGuesser.setPreference(this.lazyCountry))
                : this.$nextTick(() => {
                      this.lazyCountry || (this.lazyCountry = t)
                  }),
                this.onLazyCountryOrValueChange(),
                this.validate()
        },
        onLazyValueChange() {
            const e = (this.lazyValue || '').startsWith('+')
                ? void 0
                : this.lazyCountry
            const o = this.makePhoneNumber(this.lazyValue, e).regionCode
            o &&
                this.lazyCountry !== o &&
                this.filteredCountriesByIso2[o] &&
                (this.lazyCountry = o),
                this.onLazyCountryOrValueChange()
        },
        onLazyCountryOrValueChange() {
            this.lazyPhone = this.makePhoneNumber(
                this.lazyValue,
                this.lazyCountry
            )
            // This condition is for Lao Phone Numbers Only
            if (
                this.lazyPhone.regionCode === 'LA' &&
                this.lazyPhone.valid === false
            ) {
                const { e164 } = this.lazyPhone.number
                if (e164) {
                    const laoPhoneRegex = /^\+85620[25789]\d{7}$/
                    if (laoPhoneRegex.test(e164)) {
                        this.lazyPhone.valid = true
                    }
                }
            }
            this.lazyPhone.valid &&
                (this.lazyValue = this.formatPhoneNumber(this.lazyPhone))
        },
        onLazyPhoneChange() {
            const e = this.lazyPhone.number.e164 || this.lazyPhone.number.input
            e !== this.value && this.$emit('input', e)
        },
        validate() {
            return this.$refs.phoneInput.validate()
        },
        makePhoneNumber(e, t) {
            return d.make(e, t).toJSON()
        },
        formatPhoneNumber(e) {
            return d.format(e, this.displayFormat)
        },
        async initializeCountry() {
            if (!this.lazyCountry) {
                if (this.filteredCountries.length === 1) {
                    this.lazyCountry = this.filteredCountries[0].iso2
                    return
                }
                if (!this.disableGuessingCountry) {
                    this.guessingCountry = !0
                    const e = this.findCountry(
                        await this.countryGuesser.guess()
                    )
                    !this.lazyCountry && e && (this.lazyCountry = e.iso2),
                        (this.guessingCountry = !1)
                }
                this.lazyCountry = this.lazyCountry || this.activeCountry.iso2
            }
        },
        getCountryText(e) {
            return `${e.name} ${e.iso2} ${e.dialCode}`
        },
        getCountries(e = []) {
            return e.map((t) => this.findCountry(t)).filter((t) => !!t)
        },
        findCountry(e) {
            return this.allCountriesByIso2[l(e)]
        },
        computeMessage(e, t) {
            return typeof e === 'function' ? e(t) : e
        },
    },
})
const T = function () {
    const e = this
    const t = e.$createElement
    const o = e._self._c || t
    return o(
        'div',
        {
            staticClass: 'v-phone-input',
        },
        [
            o(
                e.enableSearchingCountry ? 'v-autocomplete' : 'v-select',
                e._b(
                    {
                        ref: 'countryInput',
                        tag: 'component',
                        staticClass: 'v-phone-input__country',
                        attrs: {
                            label: e.computedCountryLabel,
                            'aria-label': e.computedCountryAriaLabel,
                            items: e.countriesItems,
                            autocomplete: e.enableSearchingCountry
                                ? 'new-password'
                                : void 0,
                            'aria-autocomplete': e.enableSearchingCountry
                                ? 'off'
                                : void 0,
                            loading:
                                e.loading ||
                                (!e.disableGuessLoading && e.guessingCountry),
                            disabled: e.disabled,
                            readonly: e.readonly,
                            outlined: e.outlined,
                            filled: e.filled,
                            shaped: e.shaped,
                            flat: e.flat,
                            solo: e.solo,
                            'solo-inverted': e.soloInverted,
                            'background-color': e.backgroundColor,
                            color: e.color,
                            dark: e.dark,
                            light: e.light,
                            rounded: e.rounded,
                            dense: e.dense,
                            height: e.height,
                            'loader-height': e.loaderHeight,
                            reverse: e.reverse,
                            'single-line': e.singleLine,
                            'item-text': e.getCountryText,
                            'item-value': 'iso2',
                        },
                        scopedSlots: e._u(
                            [
                                {
                                    key: 'selection',
                                    fn: function (n) {
                                        return [
                                            e._t(
                                                'selection',
                                                function () {
                                                    return [
                                                        e._t(
                                                            'country-icon',
                                                            function () {
                                                                return [
                                                                    e.countryIconComponent
                                                                        ? [
                                                                              o(
                                                                                  e.countryIconComponent,
                                                                                  {
                                                                                      tag: 'component',
                                                                                      attrs: {
                                                                                          country:
                                                                                              e.activeCountry,
                                                                                          decorative:
                                                                                              !1,
                                                                                      },
                                                                                  }
                                                                              ),
                                                                          ]
                                                                        : o(
                                                                              'span',
                                                                              {
                                                                                  domProps:
                                                                                      {
                                                                                          textContent:
                                                                                              e._s(
                                                                                                  `+${e.activeCountry.dialCode}`
                                                                                              ),
                                                                                      },
                                                                              }
                                                                          ),
                                                                ]
                                                            },
                                                            {
                                                                country:
                                                                    e.activeCountry,
                                                                decorative: !1,
                                                            }
                                                        ),
                                                    ]
                                                },
                                                null,
                                                n
                                            ),
                                        ]
                                    },
                                },
                                {
                                    key: 'item',
                                    fn: function (n) {
                                        return [
                                            e._t(
                                                'item',
                                                function () {
                                                    return [
                                                        e.countryIconComponent ||
                                                        e.$scopedSlots[
                                                            'country-icon'
                                                        ]
                                                            ? o(
                                                                  'v-list-item-icon',
                                                                  [
                                                                      e._t(
                                                                          'country-icon',
                                                                          function () {
                                                                              return [
                                                                                  o(
                                                                                      e.countryIconComponent,
                                                                                      {
                                                                                          tag: 'component',
                                                                                          attrs: {
                                                                                              country:
                                                                                                  n.item,
                                                                                              decorative:
                                                                                                  !0,
                                                                                          },
                                                                                      }
                                                                                  ),
                                                                              ]
                                                                          },
                                                                          {
                                                                              country:
                                                                                  n.item,
                                                                              decorative:
                                                                                  !0,
                                                                          }
                                                                      ),
                                                                  ],
                                                                  2
                                                              )
                                                            : e._e(),
                                                        o(
                                                            'v-list-item-content',
                                                            [
                                                                o(
                                                                    'v-list-item-title',
                                                                    [
                                                                        e._t(
                                                                            'country-name',
                                                                            function () {
                                                                                return [
                                                                                    e._v(
                                                                                        ' ' +
                                                                                            e._s(
                                                                                                n
                                                                                                    .item
                                                                                                    .name
                                                                                            ) +
                                                                                            ' '
                                                                                    ),
                                                                                    o(
                                                                                        'span',
                                                                                        {
                                                                                            staticClass:
                                                                                                'text-body-2 text--secondary',
                                                                                        },
                                                                                        [
                                                                                            e._v(
                                                                                                ' +' +
                                                                                                    e._s(
                                                                                                        n
                                                                                                            .item
                                                                                                            .dialCode
                                                                                                    ) +
                                                                                                    ' '
                                                                                            ),
                                                                                        ]
                                                                                    ),
                                                                                ]
                                                                            },
                                                                            {
                                                                                country:
                                                                                    n.item,
                                                                            }
                                                                        ),
                                                                    ],
                                                                    2
                                                                ),
                                                            ],
                                                            1
                                                        ),
                                                    ]
                                                },
                                                null,
                                                n
                                            ),
                                        ]
                                    },
                                },
                            ],
                            null,
                            !0
                        ),
                        model: {
                            value: e.lazyCountry,
                            callback: function (n) {
                                e.lazyCountry = n
                            },
                            expression: 'lazyCountry',
                        },
                    },
                    'component',
                    e.computedCountryProps,
                    !1
                )
            ),
            o(
                'v-text-field',
                e._b(
                    {
                        ref: 'phoneInput',
                        staticClass: 'v-phone-input__phone',
                        attrs: {
                            label: e.computedLabel,
                            'aria-label': e.computedAriaLabel,
                            placeholder: e.computedPlaceholder,
                            hint: e.computedHint,
                            'persistent-hint': e.persistentHint,
                            'persistent-placeholder': e.persistentPlaceholder,
                            rules: e.mergedRules,
                            'append-icon': e.appendIcon,
                            'append-outer-icon': e.appendOuterIcon,
                            'prepend-inner-icon': e.prependInnerIcon,
                            'prepend-icon': e.prependIcon,
                            'clear-icon': e.clearIcon,
                            loading: e.loading,
                            disabled: e.disabled,
                            readonly: e.readonly,
                            outlined: e.outlined,
                            filled: e.filled,
                            shaped: e.shaped,
                            flat: e.flat,
                            solo: e.solo,
                            'solo-inverted': e.soloInverted,
                            'background-color': e.backgroundColor,
                            color: e.color,
                            dark: e.dark,
                            light: e.light,
                            rounded: e.rounded,
                            dense: e.dense,
                            height: e.height,
                            'loader-height': e.loaderHeight,
                            reverse: e.reverse,
                            'single-line': e.singleLine,
                            clearable: e.clearable,
                            autofocus: e.autofocus,
                            error: e.error,
                            'error-count': e.errorCount,
                            'error-messages': e.errorMessages,
                            messages: e.messages,
                            success: e.success,
                            'success-messages': e.successMessages,
                            'validate-on-blur': e.validateOnBlur,
                            type: 'tel',
                        },
                        on: {
                            blur: function (n) {
                                return e.$emit('blur', n)
                            },
                            focus: function (n) {
                                return e.$emit('focus', n)
                            },
                            click: function (n) {
                                return e.$emit('click', n)
                            },
                            'click:append': function (n) {
                                return e.$emit('click:append', n)
                            },
                            'click:append-outer': function (n) {
                                return e.$emit('click:append-outer', n)
                            },
                            'click:clear': function (n) {
                                return e.$emit('click:clear', n)
                            },
                            'click:prepend': function (n) {
                                return e.$emit('click:prepend', n)
                            },
                            'click:prepend-inner': function (n) {
                                return e.$emit('click:prepend-inner', n)
                            },
                            keydown: function (n) {
                                return e.$emit('keydown', n)
                            },
                            mousedown: function (n) {
                                return e.$emit('mousedown', n)
                            },
                            mouseup: function (n) {
                                return e.$emit('mouseup', n)
                            },
                            'update:error': function (n) {
                                return e.$emit('update:error', n)
                            },
                        },
                        scopedSlots: e._u(
                            [
                                {
                                    key: 'append',
                                    fn: function () {
                                        return [e._t('append')]
                                    },
                                    proxy: !0,
                                },
                                {
                                    key: 'append-outer',
                                    fn: function () {
                                        return [e._t('append-outer')]
                                    },
                                    proxy: !0,
                                },
                                {
                                    key: 'counter',
                                    fn: function (n) {
                                        return [e._t('counter', null, null, n)]
                                    },
                                },
                                {
                                    key: 'label',
                                    fn: function () {
                                        return [e._t('label')]
                                    },
                                    proxy: !0,
                                },
                                {
                                    key: 'message',
                                    fn: function (n) {
                                        return [e._t('message', null, null, n)]
                                    },
                                },
                                {
                                    key: 'prepend',
                                    fn: function () {
                                        return [e._t('prepend')]
                                    },
                                    proxy: !0,
                                },
                                {
                                    key: 'prepend-inner',
                                    fn: function () {
                                        return [e._t('prepend-inner')]
                                    },
                                    proxy: !0,
                                },
                                {
                                    key: 'progress',
                                    fn: function () {
                                        return [e._t('progress')]
                                    },
                                    proxy: !0,
                                },
                            ],
                            null,
                            !0
                        ),
                        model: {
                            value: e.lazyValue,
                            callback: function (n) {
                                e.lazyValue = n
                            },
                            expression: 'lazyValue',
                        },
                    },
                    'v-text-field',
                    e.computedPhoneProps,
                    !1
                )
            ),
        ],
        1
    )
}
const E = []
function H(e, t, o, n, u, y, h, P) {
    const i = typeof e === 'function' ? e.options : e
    t && ((i.render = t), (i.staticRenderFns = o), (i._compiled = !0)),
        n && (i.functional = !0),
        y && (i._scopeId = 'data-v-' + y)
    let a
    if (
        (h
            ? ((a = function (s) {
                  ;(s =
                      s ||
                      (this.$vnode && this.$vnode.ssrContext) ||
                      (this.parent &&
                          this.parent.$vnode &&
                          this.parent.$vnode.ssrContext)),
                      !s &&
                          typeof __VUE_SSR_CONTEXT__ < 'u' &&
                          (s = __VUE_SSR_CONTEXT__),
                      u && u.call(this, s),
                      s &&
                          s._registeredComponents &&
                          s._registeredComponents.add(h)
              }),
              (i._ssrRegister = a))
            : u &&
              (a = P
                  ? function () {
                        u.call(
                            this,
                            (i.functional ? this.parent : this).$root.$options
                                .shadowRoot
                        )
                    }
                  : u),
        a)
    )
        if (i.functional) {
            i._injectStyles = a
            const z = i.render
            i.render = function (I, m) {
                return a.call(m), z(I, m)
            }
        } else {
            const f = i.beforeCreate
            i.beforeCreate = f ? [].concat(f, a) : [a]
        }
    return {
        exports: e,
        options: i,
    }
}
const g = {}
const U = /* @__PURE__ */ H(R, T, E, !1, j, null, null, null)
function j(e) {
    for (const t in g) this[t] = g[t]
}
const D = /* @__PURE__ */ (function () {
    return U.exports
})()
function q(e) {
    return (t, o) => {
        if (o)
            throw new Error(
                'options must be passed when calling createVPhoneInput'
            )
        N(e || {}), t.component('VPhoneInput', D)
    }
}
class Q extends p {
    constructor(t = {}) {
        super(),
            (this.storage = t.storage || localStorage),
            (this.key = t.key || 'v_phone_input_country')
    }

    async guess() {
        const t = this.retrieveStoredCountry()
        if (t) return t
        const o = await super.guess()
        return o && this.saveStoredCountry(o), o
    }

    setPreference(t) {
        this.saveStoredCountry(t)
    }

    retrieveStoredCountry() {
        return this.storage.getItem(this.key) || void 0
    }

    saveStoredCountry(t) {
        this.storage.setItem(this.key, t)
    }

    getStorage() {
        return this.storage
    }

    getKey() {
        return this.key
    }
}
export {
    G as DEFAULT_OPTIONS,
    p as Ip2cCountryGuesser,
    A as MemoIp2cCountryGuesser,
    d as PhoneUtils,
    Q as StorageMemoIp2cCountryGuesser,
    v as VPhoneCountrySpan,
    M as VPhoneCountrySprite,
    O as VPhoneCountrySvg,
    D as VPhoneInput,
    w as countries,
    q as createVPhoneInput,
    r as getOption,
    N as mergeOptions,
}
